<template>
  <div class="card">
    <h5>Пользователи</h5>
    <DataTable
      :value="usersList"
      data-key="id"
      v-model:filters="filters"
      filterDisplay="menu"
      :loading="loading"
      responsiveLayout="scroll"
      paginator
      lazy
      :rows="itemsPerPage"
      :totalRecords="totalItems"
      @filter="onFilter"
      @sort="onSort"
      @page="setPage"
    >
      <template #empty> No customers found. </template>
      <template #loading> Loading customers data. Please wait. </template>

      <Column header="Фамилия" field="iName">
        <template #body="slot">
          {{ slot.data.iName ? slot.data.iName : '--' }}
        </template>
      </Column>
      <Column
        field="createdAt"
        header="Date"
        sortable
        filterField="createdAt"
        dataType="date"
        style="min-width: 10rem"
        :showFilterMenu="true"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showClear="true"
      >
        <template #body="{ data }">
          {{ moment(data.createdAt).format('DD.MM.YYYY') }}
        </template>
        <template #filter="{ filterModel }">
          <Calendar
            v-model="filterModel.value"
            dateFormat="mm/dd/yy"
            placeholder="диапазон от/до"
            mask="99/99/9999"
            :showClear="true"
          />
        </template>
      </Column>
      <Column header="Создан" field="createdAt">
        <template #body="slot">
          {{
            slot.data.createdAt
              ? moment(slot.data.createdAt).format('DD.MM.YYYY')
              : '--'
          }}
        </template>
      </Column>
      <Column header="Компаний" field="companies.length" />
      <Column
        field="isConfirmed"
        header="Верифирован"
        :showFilterMenu="true"
        :showFilterMatchModes="false"
      >
        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.value"
            :options="statuses"
            placeholder="Select status"
            class="p-column-filter"
            style="min-width: 8rem"
            :showClear="true"
          >
            <template #option="slotProps">
              <Tag
                :value="slotProps.option"
                :severity="getStatus(slotProps.option)"
              />
            </template>
          </Dropdown>
        </template>
        <template #body="{ data }">
          <Tag
            :value="data.isConfirmed"
            :severity="getStatus(data.isConfirmed)"
          />
        </template>
      </Column>
      <Column class="bg-white border-none flex justify-content-end">
        <template #body="slot">
          <Button
            icon="pi pi-info-circle"
            class="p-button-rounded p-button-warning m-1"
            @click="info(slot.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    v-model:visible="dialogInfo"
    :header="`Информация ${userInfo.iName}`"
    style="width: 70vw"
    class="p-fluid"
  >
    <div class="modal_info">
      <div class="up flex">
        <div class="col-6">
          <div class="distance">
            <p>
              ФИО: {{ userInfo.fName }} {{ userInfo.iName }}
              {{ userInfo.oName }}
            </p>
            <p>Email: {{ userInfo.email }}</p>
            <p>Email подтвержден: {{ userInfo.isConfirmed }}</p>
            <p>Компаний: {{ userInfo.companies.length }} шт</p>
          </div>
        </div>
        <div class="col-6">
          <div class="distance">
            <p>
              Зарегистрирован:
              {{ moment(userInfo.createdAt).format('DD.MM.YYYY') }}
            </p>
            <p>2FA: {{ userInfo.is2FaEnabled }}</p>
            <p>KYS: {{ userInfo.isKYSVerified }}</p>
          </div>
        </div>
      </div>
      <div class="down">
        <DataTable :value="userCompanies" scrollable showGridlines>
          <Column
            style="word-break: break-all"
            field="nameEng"
            header="nameEng"
          ></Column>
          <Column
            style="word-break: break-all"
            field="nameRus"
            header="nameRus"
          ></Column>
          <Column
            style="word-break: break-all"
            field="country.name"
            header="country"
          ></Column>
          <Column
            style="word-break: break-all"
            field="addressEng"
            header="addressEng"
          ></Column>
          <Column
            style="word-break: break-all"
            field="addressRus"
            header="addressRus"
          ></Column>
          <Column
            style="word-break: break-all"
            field="url"
            header="url"
          ></Column>
          <Column
            style="word-break: break-all"
            field="isVerified"
            header="isVerified"
          ></Column>
          <Column
            style="word-break: break-all"
            field="isVerifyRequest"
            header="isVerifyRequest"
          ></Column>
          <Column
            style="word-break: break-all"
            field="descriptionEng"
            header="descriptionEng"
          ></Column>
          <Column
            style="word-break: break-all"
            field="descriptionRus"
            header="descriptionRus"
          ></Column>
        </DataTable>
      </div>

      <!-- <div class="">
        <div class="distance">
          <p>Компаний: {{ userInfo.companies.length }} шт</p>
        </div>
      </div> -->
    </div>
  </Dialog>
</template>

<script>
import moment from 'moment'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'MarketingUsers',
  data() {
    return {
      usersList: [],
      userCompanies: [],
      dialogInfo: false,
      userInfo: [],
      page: 1,
      itemsPerPage: 30,
      totalItems: 10,
      statuses: [false, true],
      filters: {
        isConfirmed: {
          value: null,
        },
        createdAt: {
          operator: 'AND',
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.DATE_BEFORE,
            },
            {
              value: null,
              matchMode: FilterMatchMode.DATE_BEFORE,
            },
          ],
        },
      },
    }
  },
  async mounted() {
    await this.getUserList()
  },

  computed: {
    header() {
      return this.modifyNft === null ? 'Добавить nft' : 'Редактировать nft'
    },
    button() {
      return this.modifyNft === null ? 'Добавить' : 'Редактировать'
    },
  },
  methods: {
    moment(date) {
      return moment(date)
    },

    async getUserList(value) {
      let verify = value ? value.filters.isConfirmed.value : null

      let atFrom = null
      let atTo = null
      let order = 'asc'

      if (value) {
        if (value['filters']['createdAt']['constraints'][0]) {
          atFrom = value['filters']['createdAt']['constraints'][0]['value']
            ? moment(value.filters.createdAt.constraints[0].value).format(
                'YYYY-MM-DD 00:00'
              )
            : null
        }
        if (value['filters']['createdAt']['constraints'][1]) {
          atTo = value['filters']['createdAt']['constraints'][1]['value']
            ? moment(value.filters.createdAt.constraints[0].value).format(
                'YYYY-MM-DD 00:00'
              )
            : null
        }
        if (atFrom == atTo) {
          atTo = null
        }
        if (value.sortOrder == 1) {
          order = 'desc'
        }
        if (value.sortOrder == -1) {
          order = 'asc'
        }
      }
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
        sort: 'createdAt',
        createdAtFrom: atFrom,
        createdAtTo: atTo,
        isConfirmed: verify,
        order: order,
      }
      try {
        const resp = await this.axios.get('api/users', { headers, params })
        this.usersList = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (error) {
        console.log('err', error)
      }
    },

    async info(data) {
      this.userInfo = data
      let res = await this.axios.get(
        `api/companies?page=1&itemsPerPage=30&userId=${data.id}`
      )
      this.userCompanies = res.data
      this.dialogInfo = true
    },
    setPage(event) {
      this.page = event.page + 1
      this.getUserList(event)
    },
    onFilter(event) {
      this.page = 1
      this.getUserList(event)
    },
    onSort(event) {
      this.getUserList(event)
    },
    getStatus(status) {
      switch (status) {
        case false:
          return 'danger'
        case true:
          return 'success'
        default:
          return null
      }
    },
  },
}
</script>

<style scoped>
.distance {
  margin-top: 7px;
}
.distance_button {
  margin-top: 20px;
}
</style>
